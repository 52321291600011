
import {
    onMounted,
    reactive,
    ref,
    unref,
    toRefs,
    defineComponent,
} from "vue";
import FindKey from '@/components/find-key/Index.vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from '@/store'
import moment from "moment";
import { useI18n } from 'vue-i18n'
import i18n from '@/locales/index'
import { ElForm, ElMessageBox, ElMessage } from 'element-plus';
import { repassword, editUserPassword, editEmailUserPassword, getUserPassQa, getEmailUserPassQa } from '@/apis/user'
let _t = i18n.global.t
let re = /^[a-zA-Z0-9 ；，、’‘：？——【】《》“”！!'"_<>`,·。≈{}~～()-￥%……（^）|?,@#$&()|;:"<>*\\/\n]+$/;
interface formObject {
    old_password?: string,
    password?: string,
    confirm_password?: string,
}
interface paramsType {
    formChangePass?: formObject,
    changePassRules?: Object,
    isSuccess: boolean,
    step: number,
    isAdmin: boolean,
}

export default {
    components: {
        FindKey,
    },
    props: [],
    setup(props, context) {
        const isLoading = ref(true);
        const changeLoading=(value:boolean)=>{
            setTimeout(()=>{
                isLoading.value=value;
            },1000);
        }
        const changePassForm = ref(ElForm);
        const store = useStore()
        const checRekNewKey = (rule: any, value: any, callback: any) => {
            if (value === '') {
                callback(new Error(_t('system.password.confirmPasswordempty')))
            } else if (value.length > 20) {
                callback(new Error(_t('system.password.confirmPasswordRule1')))
            } else if (value.length < 6) {
                callback(new Error(_t('system.password.confirmPasswordRule2')))
            } else if (!re.test(value)) {
                callback(new Error(_t('system.password.passwordRule3')))
            } else if (state.formChangePass && (value !== state.formChangePass?.password)) {
                callback(new Error(_t('system.password.passwordRule4')))
            } else {
                callback()
            }
        }
        const checkNewKey = (rule: any, value: any, callback: any) => {
            if (value === '') {
                callback(new Error(_t('system.password.passwordempty')))
            } else if (value.length > 20) {
                callback(new Error(_t('system.password.passwordRule1')))
            } else if (value.length < 6) {
                callback(new Error(_t('system.password.passwordRule2')))
            } else if (!re.test(value)) {
                callback(new Error(_t('system.password.passwordRule3')))
            } else if (state.formChangePass && state.formChangePass?.confirm_password !== '') {
                unref(changePassForm).validateField('confirm_password')
            }
            callback()
        }
        const state: paramsType = reactive({
            isAdmin: store.state.user.roles.indexOf('admin') >= 0,
            isSuccess: false,
            step: 0,
            formChangePass: {
                old_password: '',
                password: '',
                confirm_password: ''
            },
            changePassRules: {
                old_password: [{ required: true, message: _t('system.password.currentPasswordEmpty'), trigger: 'blur' }],
                password: [{ validator: checkNewKey, trigger: 'blur' }],
                confirm_password: [{ validator: checRekNewKey, trigger: 'blur' }],
            },
        })
        const methods = reactive({
            submit() {
                unref(changePassForm).validate((valid) => {
                    if (valid) {
                        if (store.state.user.name == '') {
                            // 邮箱用户更新
                            const params = Object.assign(state.formChangePass, {
                                company_id: store.state.user.company,
                                email: store.state.user.email
                            });
                            editEmailUserPassword(params).then((res: any) => {
                                if (res) {
                                    if (res.code === 200) {
                                        unref(changePassForm).resetFields()
                                        state.isSuccess = true
                                    } else if (res.code === 40003) {
                                        ElMessage.warning(_t('system.password.passwordWrong'))
                                    } else {
                                        ElMessage.warning(_t('system.password.changePasswordError'))
                                    }
                                }
                            }).catch(error => console.log('error', error))
                        } else {
                            //用户名用户更新
                            const params = Object.assign(state.formChangePass, {
                                company_id: store.state.user.company,
                            });
                            editUserPassword(store.state.user.name, params).then((res: any) => {
                                if (res) {
                                    if (res.code === 200) {
                                        unref(changePassForm).resetFields()
                                        state.isSuccess = true
                                    } else if (res.code === 40003) {
                                        ElMessage.warning(_t('system.password.passwordWrong'))
                                    } else {
                                        ElMessage.warning(_t('system.password.changePasswordError'))
                                    }
                                }
                            }).catch(error => console.log('error', error))
                        }
                    } else {
                        return false
                    }
                })
            },
            getCurrentQa() {
                if (store.state.user.name == '') {
                    getEmailUserPassQa({ email: store.state.user.email }).then((res: any) => {
                        if (res.code === 200) {
                            if (res.data?.lists?.length > 0) {
                                state.step = 1
                            } else if (!state.isAdmin) { // 非admin用户不需要填安全问题
                                state.step = 1
                            } else {
                                state.step = 0
                            }
                        } else {
                            ElMessage.warning(_t('system.qa.getQaListError'))
                        }
                        changeLoading(false);
                    })
                } else {
                    getUserPassQa({ name: store.state.user.name }).then((res: any) => {
                        if (res.code === 200) {
                            if (res.data?.lists?.length > 0) {
                                state.step = 1
                            } else if (!state.isAdmin) { // 非admin用户不需要填安全问题
                                state.step = 1
                            } else {
                                state.step = 0
                            }
                        } else {
                            ElMessage.warning(_t('system.qa.getQaListError'))
                        }
                        changeLoading(false);
                    })
                }

            },
            toSetQa() {
                context.emit('jumpToQuestion')
            },
            toInitialzation() {
                state.isSuccess = false
            },
            reset() {
                state.step == 1 ? unref(changePassForm).resetFields() : null
            }

        });
        onMounted(() => {
        })
        return {
            ...toRefs(state),
            ...toRefs(methods),
            changePassForm,
            isLoading
        }
    }
}
