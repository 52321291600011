<template>
	<div>
		<el-breadcrumb separator="/" class="breadcrumb">
			<el-breadcrumb-item>{{ $t("route.systemSet") }}</el-breadcrumb-item>
		</el-breadcrumb>

		<div class="contentBox">
			<el-tabs type="card" tab-position="left" class="tabBox" v-model="currentMenu" @tab-click="changeTab">
  			<el-tab-pane :label="$t('system.robotManage')" class="tabTitle" :name="1" :key="1">
					<p class="titleName">{{$t('system.robotManage')}}</p>
					<p>
						<span style="padding-right: 24px">{{$t('system.robotRule1')}}</span>	
						<el-switch
							v-model="permission"
							active-color="#1890FF"
							@change="handlePermission"
							inactive-color="#999">
						</el-switch>
					</p>
				</el-tab-pane>
  			<!-- <el-tab-pane :label="$t('system.tab2')" class="tabTitle" disabled :name="2" :key="2"></el-tab-pane> -->
  			<el-tab-pane :label="$t('system.tab2')" class="tabTitle" :name="3" :key="3">
					<ChangePass @jumpToQuestion="jumpToQuestion" ref="passwordChildRef" />
				</el-tab-pane>
  			<el-tab-pane :label="$t('system.tab3')" class="tabTitle" :name="4" :key="4" v-if="isAdmin">
					<QuestionSet ref="questionChildRef" />
				</el-tab-pane>
				<el-tab-pane :label="$t('system.language')" class="tabTitle" :name="5" :key="5">
					<p class="titleName">{{$t('system.language')}}</p>
					<el-radio-group v-model="currentLanguage" @change="changeLan" class="radioGroup">
    				<el-radio label="en">English</el-radio>
					<el-radio label="es">Español</el-radio>
    				<el-radio label="zh-cn">中文</el-radio>
  				</el-radio-group>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="footer_set">
      <p><Agreement /></p>
      <!-- <p>{{ $t("login.companyProducts") }}</p> -->
    </div>	
	</div>
</template>

<script>
import BreadCrumb from '@/components/bread-crumb/Index.vue'
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from 'vue'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import { useI18n } from 'vue-i18n'
import { setLang, robotPermission, langInfo, robotPermissionInfo } from '@/apis/user'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus';
import moment from 'moment'
import { getHM, totalImg } from '@/utils/robot' 
import Agreement from '@/views/user-manager/login/Agreement.vue'
import ChangePass from './ChangePass.vue'
import QuestionSet from './QuestionSet.vue'
import { getLanguage } from '@/utils/cookies'
import i18n from '@/locales/index'
const _t = i18n.global.t

export default {
  components: {
		Agreement,
		ChangePass,
		QuestionSet,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
		const { locale } = useI18n()
		const passwordChildRef = ref(null)
		const questionChildRef = ref(null)
    const state = reactive({
			isAdmin: store.state.user.roles.indexOf('admin')>=0,
			permission: true,
			currentMenu: 1,
			currentLanguage: getLanguage(),
			getTaskList() {
				const { params } = route
				taskInfo(params).then((res => {
        	if (res.code == 200) {
						const list = []
						res.data.map(item => {
							list.push(JSON.parse(item))
						})
					}
				}))
			},
			handleLang(language) {
				const params = { language }
				const lang = language == 'CN' ? 'zh-cn' : 'en'
				locale.value = lang
        store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE, lang)
				setLang(params).then(res => {
					if (res.code == 200) {
						ElMessage.closeAll()
						ElMessage({
							type: 'success',
							message: _t('system.setSuccess')
						})
					}
				})
			},
			handlePermission(permission) {
				const params = { permission }
				robotPermission(params).then(res => {
					if (res.code == 200) {
						ElMessage.closeAll()
						ElMessage({
							type: 'success',
							message: _t('system.setSuccess')
						})
					}
				})
			},
			getData() {
				// langInfo().then(res => {
				// 	if (res.code == 200) {
				// 		state.language = res.data
				// 	}
				// })
				robotPermissionInfo().then(res => {
					if (res.code == 200) {
						state.permission = res.data.permission
					}
				})
			},
			jumpToQuestion() {
				state.currentMenu=4
				state.initqa()
			},
			changeTab(tab, event) {
				if(tab.props.name===3) { // 修改密码
					state.initpassword()
				}else if(tab.props.name===4) { // 安全问题
					state.initqa()
				}
			},
			initqa() {
				questionChildRef._value.getCurrentQa()
				passwordChildRef._value.toInitialzation()
			},
			initpassword() {
				passwordChildRef._value.getCurrentQa()
				passwordChildRef._value.reset()
				questionChildRef._value.toInitialzation()
			},
			changeLan(lang) {
				store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE, lang)
				localStorage.setItem('setTab', 5)
				location.reload()
			}
    })
    onMounted(() => {
			state.getData()
			if(localStorage.getItem('setTab')) {
				state.currentMenu = Number(localStorage.getItem('setTab'))
				localStorage.removeItem('setTab')
			}
    })
    return {
			...toRefs(state),
			passwordChildRef,
			questionChildRef,
    }
  }
}
</script>
<style lang="scss" >
.contentBox {
	// border: 1px solid #e8e8e8;
	padding: 24px;
	margin: 24px;
	border-radius: 2px;
	// box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	background: #fff;
	.titleName {
		font-size: 18px;
		font-weight: bold;
		margin: 0 0 20px;
	}
}



.footer_set {
    position: absolute;
    text-align: center;
    color: #666;
    bottom: 20px;
    left: 50%;
    margin-left: -130px;
	p {
		margin-bottom: 6px;
		font-size: 14px;
	}
}

.radioGroup{
	margin: 20px 0;
}
.tabBox{
	.el-tabs__header{
		border: none;
		.el-tabs__item {
			font-weight: bold;
		}
		// .el-tabs__item:nth-child(2) {
		// 	font-weight: bold;
		// 	border-bottom: none;
		// }
		// .el-tabs__item:nth-child(3) {
		// 	border-bottom: none;
		// 	border-top: none;
		// 	padding-left: 40px;
		// }
		// .el-tabs__item:nth-child(4) {
		// 	border-top: none;
		// 	padding-left: 40px;
		// }
		.el-tabs__item{
			line-height: 34px;
    	height: unset;
			padding: 10px 50px 10px 30px;
			color: #000;
		}
		.is-active{
			background: #b4dbff;
		}
	}
	.el-tabs__content{
		padding: 20px 50px;
		border: #e4e7ed solid 1px;
	}
}



</style>
