<!--
 * @Author: hongying
 * @Date: 2022-03-04 17:32:35
 * @LastEditTime: 2024-08-16 14:53:41
 * @LastEditors: 郭长生
 * @FilePath: \ADIS\src\components\findKey\Index.vue
-->
<template>
    <div v-loading="isLoading" element-loading-background="rgba(255,255,255,0.97)">
        <div class="questionSetBox" v-if="!isSuccess">
            <p class="titleName">
                {{ $t('system.tab3') }}
            </p>
            <div v-if="step == 1">
                <p>{{ $t('system.qa.qaTips2') }}</p>
                <el-form :model="item" ref="oldForm" :rules="oldQaRules" label-position="top" v-for="(item, i) in oldQuestionList" :key="i">
                    <el-form-item
                        :label="oldQuestionList[i].question"
                        prop="answer"
                        :rules="[
                            {
                                validator: (rule, value, callback) => customRule(i, rule, value, callback),
                                trigger: 'blur'
                            }
                        ]"
                    >
                        <el-input v-model="oldQuestionList[i].answer" :placeholder="$t('system.password.pleaseEnter')" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-else-if="step == 2">
                <p>{{ $t('system.qa.qaTips3') }}</p>
                <el-form :model="formNewQa" ref="newForm" :rules="newQaRules" label-position="top">
                    <el-form-item :label="$t('system.qa.choiceQa')" prop="question">
                        <el-select v-model="formNewQa.question" :placeholder="$t('system.qa.choiceQa')" clearable>
                            <el-option v-for="(item, index) in questionList" :key="index" :label="language === 'zh-cn' ? item.content_cn : language === 'es' ? item.content_es : item.content_en" :value="language === 'zh-cn' ? item.content_cn : language === 'es' ? item.content_es : item.content_en"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="answer" v-show="formNewQa.question">
                        <el-input v-model="formNewQa.answer" :placeholder="$t('system.qa.enterAnswer')" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <el-button type="primary" @click="nextButton" style="margin: 20px 0;" @keyup.space.prevent.native @keydown.enter.prevent.native :disabled="(step == 1 && !oldQuestionList[0].answer) || (step == 2 && !formNewQa.answer)">
                {{ $t('system.qa.nextStep') }}
            </el-button>
        </div>
        <div v-else class="successResult">
            <i class="el-icon-success resultIcon" />
            <div class="resultTitle">
                {{ $t('system.qa.setQaSuccess') }}
            </div>
            <el-button type="primary" @click="toInitialzation()" @keyup.space.prevent.native @keydown.enter.prevent.native>
                {{ $t('system.back') }}
            </el-button>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, reactive, ref, unref, toRefs, defineComponent, computed } from 'vue';
import { getPassQuestionList, getUserPassQa, getEmailUserPassQa, checkQa, addUserQa, editUserQa } from '@/apis/user';
import { useStore } from '@/store';
import { getLanguage } from '@/utils/cookies';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import i18n from '@/locales/index';
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
const _t = i18n.global.t;

interface qalistType {
    answer: string;
    question: string;
}
interface paramsType {
    formNewQa: any;
    step: number;
    questionList: Array<{ [key: string]: any }>;
    oldQuestionList: Array<qalistType>;
    oldQaRules: any;
    newQaRules: any;
    checkOldQa: string;
    isSuccess: boolean;
    language: string;
    maginstep: boolean;
}

export default {
    props: [],
    setup(props, context) {
        const isLoading = ref(true);
        const changeLoading = (value: boolean) => {
            setTimeout(() => {
                isLoading.value = value;
            }, 1000);
        };
        const oldForm = ref(ElForm);
        const newForm = ref(ElForm);
        const store = useStore();

        const state: paramsType = reactive({
            formNewQa: { question: '', answer: '' },
            step: 1,
            questionList: [],
            oldQuestionList: [{ answer: '', question: '' }],
            checkOldQa: '',
            isSuccess: false,
            language: getLanguage() || 'en',
            maginstep: false,
            customRule: async (i, rule, value, callback) => {
                if (!value || value.trim() === '') {
                    callback(new Error(_t('system.qa.currentAnswer')));
                } else {
                    const newlist = [
                        {
                            question: state.oldQuestionList[i].question,
                            answer: value
                        }
                    ];
                    let res = await checkQa(store.state.user.name, { data: newlist });
                    if (res) {
                        if (res.code === 200) {
                            state.checkOldQa = res.data.token;
                            callback();
                        } else if (res.code === 40005) {
                            callback(new Error(_t('system.qa.answerError')));
                        } else {
                            callback(new Error(res.msg));
                        }
                    }
                    return Promise.reject(_t('system.qa.answerError'));
                }
                // }).catch((error) => { console.log('error>>>>', error); callback(new Error(_t('system.qa.failRequeat'))) })
            },
            oldQaRules: {},
            newQaRules: {
                question: [{ required: true, message: _t('system.choice'), trigger: 'change' }],
                answer: [
                    {
                        validator: (rule: any, value: any, callback: any, source, options) => {
                            if (!value || value.trim() === '') {
                                callback(new Error(_t('system.qa.currentAnswer')));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            }
        });
        const methods = reactive({
            nextButton() {
                if (state.step == 1) {
                    setTimeout(() => {
                        unref(oldForm).validate((valid: boolean) => {
                            if (valid) {
                                methods.getQaList();
                                methods.nextStep();
                            } else {
                                return false;
                            }
                        });
                    }, 1000);
                } else {
                    unref(newForm).validate((valid: boolean) => {
                        const obj = { data: state.oldQuestionList };
                        if (state.checkOldQa) {
                            editUserQa(store.state.user.name, Object.assign(state.formNewQa, obj), state.checkOldQa).then((res: any) => {
                                if (res.code === 200) {
                                    unref(newForm).resetFields();
                                    state.isSuccess = true;
                                    state.step = 1;
                                    methods.getCurrentQa();
                                } else {
                                    ElMessage.warning(_t('system.qa.setQaError'));
                                }
                            });
                        } else {
                            addUserQa(store.state.user.name, Object.assign(state.formNewQa, obj)).then((res: any) => {
                                if (res.code === 200) {
                                    unref(newForm).resetFields();
                                    state.isSuccess = true;
                                    state.step = 1;
                                    methods.getCurrentQa();
                                } else {
                                    ElMessage.warning(_t('system.qa.setQaError'));
                                }
                            });
                        }
                    });
                }
            },
            nextStep() {
                state.step += 1;
            },
            getCurrentQa() {
                if (store.state.user.name == '') {
                    getEmailUserPassQa({ email: store.state.user.email }).then((res: any) => {
                        if (res.code === 200) {
                            if (res.data?.lists?.length > 0) {
                                state.oldQuestionList = [
                                    // 多个问题此处需要修改
                                    {
                                        question: state.language === 'zh-cn' ? res.data.lists[0].content_cn : state.language === 'es' ? res.data.lists[0].content_es : res.data.lists[0].content_en,
                                        answer: ''
                                    }
                                ];
                                state.step = 1;
                                unref(oldForm) ? unref(oldForm).resetFields() : null;
                            } else {
                                state.step = 2;
                                state.oldQuestionList = [];
                                methods.getQaList();
                            }
                        } else {
                            ElMessage.warning(_t('system.qa.getQaListError'));
                        }
                        changeLoading(false);
                    });
                } else {
                    getUserPassQa({ name: store.state.user.name }).then((res: any) => {
                        if (res.code === 200) {
                            if (res.data?.lists?.length > 0) {
                                // state.oldQuestionList = res.data.lists.map((item, i) => {
                                //   return {
                                //     question: getLanguage() === 'en' ? item.content_en : item.content_cn,
                                //     answer: ''
                                //   }
                                // })
                                state.oldQuestionList = [
                                    // 多个问题此处需要修改
                                    {
                                        question: state.language === 'zh-cn' ? res.data.lists[0].content_cn : state.language === 'es' ? res.data.lists[0].content_es : res.data.lists[0].content_en,
                                        answer: ''
                                    }
                                ];
                                state.step = 1;
                                unref(oldForm) ? unref(oldForm).resetFields() : null;
                            } else {
                                state.step = 2;
                                state.oldQuestionList = [];
                                methods.getQaList();
                            }
                        } else {
                            ElMessage.warning(_t('system.qa.getQaListError'));
                        }
                        changeLoading(false);
                    });
                }
            },
            getQaList() {
                getPassQuestionList().then((res: any) => {
                    if (res.code === 200) {
                        state.questionList = res.data.lists;
                    } else {
                    }
                });
            },
            toInitialzation() {
                state.isSuccess = false;
            }
        });
        onMounted(() => {});
        return {
            ...toRefs(state),
            ...toRefs(methods),
            oldForm,
            newForm,
            isLoading
        };
    }
};
</script>
<style lang="scss" scoped>
.questionSetBox {
    font-size: 14px;

    .el-input {
        width: 450px;
    }

    .el-select {
        width: 450px;
    }

    .titleName {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 20px;
    }
}

.successResult {
    text-align: center;
    padding: 50px;

    .resultIcon {
        color: #5bd171;
        font-size: 50px;
        margin: 0 0 20px;
    }

    .resultTitle {
        font-size: 16px;
        margin: 0 0 16px;
        color: #303133;
    }
}
</style>
